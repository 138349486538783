<template>
    <div v-if="internalShow">

      <b-button-group style="display:flex; margin-bottom: 8px">
        <b-button class="buttonblock" @click="showLeadForm" squared variant="outline-dark">Richiesta Informazioni</b-button>
        <b-button class="buttonblock" @click="showEnrollForm" squared variant="outline-dark">Richiesta Iscrizione</b-button>
      </b-button-group>

      <b-form v-if="internalShowFormLead">

        <validation-observer ref="observerLead" v-show="step1FormLeadVisible">

          <validation-provider :rules="formStructure.nome.rules" :customMessages="formStructure.nome.customErrorMessages"
           v-slot="validationContext">
            <b-form-group>
            <b-form-input
              id='nome'
              v-model="formStructure.nome.userInput"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="Nome"
            ></b-form-input>
            <span>{{ validationContext.errors[0] }}</span>
            </b-form-group>
          </validation-provider>

          <validation-provider :rules="formStructure.cognome.rules" :customMessages="formStructure.cognome.customErrorMessages"
           v-slot="validationContext">
            <b-form-group>
            <b-form-input
              id='cognome'
              v-model="formStructure.cognome.userInput"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="Cognome"
            ></b-form-input>
            <span>{{ validationContext.errors[0] }}</span>
            </b-form-group>
          </validation-provider>

          <validation-provider :rules="formStructure.email.rules"
          :customMessages="formStructure.email.customErrorMessages"
           v-slot="validationContext">
            <b-form-group>
            <b-form-input
              id='email'
              v-model="formStructure.email.userInput"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="Email"
            ></b-form-input>
            <span>{{ validationContext.errors[0] }}</span>
            </b-form-group>
          </validation-provider>

          <validation-provider :rules="formStructure.telephone.rules" :customMessages="formStructure.telephone.customErrorMessages"
           v-slot="validationContext">
            <b-form-group>
            <b-form-input
              id='telephone'
              v-model="formStructure.telephone.userInput"
              type="text"
              :state="getValidationState(validationContext)"
              placeholder="Telefono"
            ></b-form-input>
            <span>{{ validationContext.errors[0] }}</span>
            </b-form-group>
          </validation-provider>


           <a href="mailto:assistenza@unimercatorum.id" v-if="failStep1Lead">  Bisogno di aiuto? Clicca qui per inviarci una mail</a>




          <b-form-group>
            <b-button v-for="(item, key) in step1FormLeadButtons" :key="key"
             :size="item.size" :variant="item.styleVariant" :type="item.type" :disabled="disableActionButtons"
             @click="callByName(item.onClick)"> {{ item.label }}
            </b-button>
          </b-form-group>
        </validation-observer>

        <div class="mx-auto" v-show="step2LeadDoneVisible">
          <h6>
            Richiesta informazioni inviata con successo
          </h6>
            <p v-if="emailError">
              Per motivi tecnici non siamo stati in grado di inviarti un E-mail di conferma, ma la tua richiesta è stata comunque presa in carico.
              Riceverai una email di conferma entro 48 ore.
              <a href="mailto:assistenza@unimercatorum.id">  Per assistenza clicca qui per inviarci una mail </a>
            </p>
            <p v-else>
              Un messaggio di conferma è stata inviata al tuo indirizzo E-mail.
              Se non trovi il messaggio, cerca anche nella cartella di spam.
            </p>
        </div>


      </b-form>

        <b-form v-if="internalShowFormEnroll">

            <validation-observer ref="observer1" v-slot="{ handleSubmit }" v-show="step1FormEnrollVisible" @submit.stop.prevent="handleSubmit(onValidateStep1)">

              <validation-provider :rules="formStructure.nome.rules" :customMessages="formStructure.nome.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='nome'
                  v-model="formStructure.nome.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Nome"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.cognome.rules" :customMessages="formStructure.cognome.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='cognome'
                  v-model="formStructure.cognome.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Cognome"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.cf.rules" :customMessages="formStructure.cf.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='cf'
                  v-model="formStructure.cf.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Codice fiscale"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.birth_date.rules" :customMessages="formStructure.birth_date.customErrorMessages"
               v-slot="validationContext">
                <label for="birth_date">{{ formStructure.birth_date.placeholder }}</label>
                <b-form-datepicker id="birth_date" size="sm" :dark='true' v-model="formStructure.birth_date.userInput" :state="getValidationState(validationContext)"
                :initial-date="formStructure.birth_date.initialBirthDate"
                :min="formStructure.birth_date.minBirthDate" :max="formStructure.birth_date.maxBirthDate" class="mb-2">
                </b-form-datepicker>
                <span>{{ validationContext.errors[0] }}</span>
              </validation-provider>

              <validation-provider :rules="formStructure.sex.rules" :customMessages="formStructure.sex.customErrorMessages"
               v-slot="validationContext">
                <b-form-group :label="formStructure.sex.placeholder">
                  <b-form-radio-group :value="formStructure.sex.userInput" :disabled='formStructure.sex.readonly'
                    id='sex' v-model="formStructure.sex.userInput" :options="formStructure.sex.choices" :state="getValidationState(validationContext)" type="radio">
                 </b-form-radio-group>
                <span style="color: red;">{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.email.rules"
              :customMessages="formStructure.email.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='email'
                  v-model="formStructure.email.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Email"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.telephone.rules" :customMessages="formStructure.telephone.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='telephone'
                  v-model="formStructure.telephone.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Telefono"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>
              <b-form-group>
                <b-button v-for="(item, key) in step1FormEnrollButtons" :key="key"
                 :size="item.size" :variant="item.styleVariant" :type="item.type" :disabled="disableActionButtons"
                  @click="callByName(item.onClick)"> {{ item.label }}
                </b-button>
              </b-form-group>
               <a href="mailto:assistenza@unimercatorum.id" v-if="failstep1">  Bisogno di aiuto? Clicca qui per inviarci una mail</a>
            </validation-observer>

            <validation-observer ref="observer2" v-slot="{ passes }" v-show="step2FormEnrollVisible" @submit.stop.prevent="passes(onvalidateStep2)">

              <validation-provider :rules="formStructure.birth_country_id.rules" :customMessages="formStructure.birth_country_id.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                <b-form-select :state="getValidationState(validationContext)"
                  id='birth_country_id' v-model="formStructure.birth_country_id.userInput" :options='$root.$data[formStructure.birth_country_id.optionRootSource] || formStructure.birth_country_id.options' >
                  <template v-slot:first>
                    <b-form-select-option value="undefined" disabled> {{ formStructure.birth_country_id.placeholder }}</b-form-select-option>
                  </template>
                </b-form-select>
                <span>{{ validationContext.errors[0] }}</span>
              </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.birth_province_id.rules" :customMessages="formStructure.birth_province_id.customErrorMessages"  v-if="formStructure.birth_province_id.render" v-slot="validationContext">
                <b-form-group>
                <b-form-select :state="getValidationState(validationContext)"
                  id='birth_province_id' v-model="formStructure.birth_province_id.userInput" :options='$root.$data[formStructure.birth_province_id.optionRootSource] || formStructure.birth_province_id.options' >
                  <template v-slot:first>
                    <b-form-select-option value="undefined" disabled> {{ formStructure.birth_province_id.placeholder }}</b-form-select-option>
                  </template>
                </b-form-select>
                <span>{{ validationContext.errors[0] }}</span>
              </b-form-group>
              </validation-provider>



              <validation-provider :rules="formStructure.birth_city_id.rules" :customMessages="formStructure.birth_city_id.customErrorMessages" v-if="formStructure.birth_city_id.render" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='birth_city_id' v-model="formStructure.birth_city_id.userInput" :options='formStructure.birth_city_id.options || $root.$data[formStructure.birth_city_id.optionRootSource]' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.birth_city_id.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>



              <validation-provider :rules="formStructure.other_city_birth.rules" :customMessages="formStructure.other_city_birth.customErrorMessages" v-if="formStructure.other_city_birth.render"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='other_city_birth'
                  v-model="formStructure.other_city_birth.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Altra città di nascita"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>



              <validation-provider :rules="formStructure.residence_country_id.rules" :customMessages="formStructure.residence_country_id.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='residence_country_id' v-model="formStructure.residence_country_id.userInput" :options='$root.$data[formStructure.residence_country_id.optionRootSource] || formStructure.residence_country_id.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.residence_country_id.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.residence_province_id.rules" :customMessages="formStructure.residence_province_id.customErrorMessages"  v-if="formStructure.residence_province_id.render" v-slot="validationContext">
                <b-form-group>
                <b-form-select :state="getValidationState(validationContext)"
                  id='residence_province_id' v-model="formStructure.residence_province_id.userInput" :options='$root.$data[formStructure.residence_province_id.optionRootSource] || formStructure.residence_province_id.options' >
                  <template v-slot:first>
                    <b-form-select-option value="undefined" disabled> {{ formStructure.residence_province_id.placeholder }}</b-form-select-option>
                  </template>
                </b-form-select>
                <span>{{ validationContext.errors[0] }}</span>
              </b-form-group>
              </validation-provider>


              <validation-provider :rules="formStructure.residence_city_id.rules" :customMessages="formStructure.residence_city_id.customErrorMessages" v-if="formStructure.residence_city_id.render" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='residence_city_id' v-model="formStructure.residence_city_id.userInput" :options='formStructure.residence_city_id.options || $root.$data[formStructure.residence_city_id.optionRootSource]' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.residence_city_id.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>



              <validation-provider :rules="formStructure.other_city_residence.rules" :customMessages="formStructure.other_city_residence.customErrorMessages" v-if="formStructure.other_city_residence.render"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='other_city_residence'
                  v-model="formStructure.other_city_residence.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Altra città di residenza"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.residence_cap.rules" :customMessages="formStructure.residence_cap.customErrorMessages" v-if="formStructure.residence_cap.render"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='residence_cap'
                  v-model="formStructure.residence_cap.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="CAP città di residenza"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>


              <validation-provider :rules="formStructure.residence_address_name.rules" :customMessages="formStructure.residence_address_name.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='residence_address_name'
                  v-model="formStructure.residence_address_name.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Indirizzo di residenza"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>


              <validation-provider :rules="formStructure.residence_address_num.rules" :customMessages="formStructure.residence_address_num.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='residence_address_num'
                  v-model="formStructure.residence_address_num.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  placeholder="Numero civico residenza"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>
              <b-form-group>
                <b-button v-for="(item, key) in step2FormEnrollButtons" :key="key"
                 :size="item.size" :variant="item.styleVariant" :type="item.type" :disabled="disableActionButtons"
                  @click="callByName(item.onClick)"> {{ item.label }}
                </b-button>
              </b-form-group>
              <a href="mailto:assistenza@unimercatorum.id" v-if="failstep2">  Bisogno di aiuto? Clicca qui per inviarci una mail</a>
          </validation-observer>


            <validation-observer ref="observer3" v-show="step3FormEnrollVisible">

              <validation-provider ref="validProvCdl" :rules="formStructure.cdl_id.rules" :customMessages="formStructure.cdl_id.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='cdl_id' v-model="formStructure.cdl_id.userInput" :options='$root.$data[formStructure.cdl_id.optionRootSource] || formStructure.cdl_id.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.cdl_id.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.pds_id.rules" :customMessages="formStructure.pds_id.customErrorMessages" v-if="formStructure.pds_id.render" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='pds_id' v-model="formStructure.pds_id.userInput" :options='formStructure.pds_id.options || $root.$data[formStructure.pds_id.optionRootSource]' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.pds_id.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.enroll_type_id.rules" :customMessages="formStructure.enroll_type_id.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='enroll_type_id' v-model="formStructure.enroll_type_id.userInput" :options='$root.$data[formStructure.enroll_type_id.optionRootSource] || formStructure.enroll_type_id.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.enroll_type_id.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.doppia_laurea.rules" :customMessages="formStructure.doppia_laurea.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='doppia_laurea' v-model="formStructure.doppia_laurea.userInput" :options='$root.$data[formStructure.doppia_laurea.optionRootSource] || formStructure.doppia_laurea.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.doppia_laurea.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider v-if="formStructure.external_universities.render" :rules="formStructure.external_universities.rules" :customMessages="formStructure.external_universities.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='external_universities' v-model="formStructure.external_universities.userInput" :options='$root.$data[formStructure.external_universities.optionRootSource] || formStructure.external_universities.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.external_universities.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.academic_year.rules" :customMessages="formStructure.academic_year.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='academic_year' v-model="formStructure.academic_year.userInput" :options='$root.$data[formStructure.academic_year.optionRootSource] || formStructure.academic_year.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.academic_year.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider ref="validProvConvention" :rules="formStructure.convention_id.rules" :customMessages="formStructure.convention_id.customErrorMessages" v-slot="validationContext">
                <b-form-group label="Convenzione:">
                  <b-form-select :state="getValidationState(validationContext)"
                    id='convention_id' v-model="formStructure.convention_id.userInput" :options='$root.$data[formStructure.convention_id.optionRootSource] || formStructure.convention_id.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.convention_id.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider ref="validProvFormProgram" :rules="formStructure.formativeprogram.rules" :customMessages="formStructure.formativeprogram.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='formativeprogram' v-model="formStructure.formativeprogram.userInput" :options='$root.$data[formStructure.formativeprogram.optionRootSource] || formStructure.formativeprogram.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.formativeprogram.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider ref="validProvProgram" :rules="formStructure.program.rules" :customMessages="formStructure.program.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='program' v-model="formStructure.program.userInput" :options='$root.$data[formStructure.program.optionRootSource] || formStructure.program.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.program.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider ref="validProvInstallments" :rules="formStructure.installments.rules" :customMessages="formStructure.installments.customErrorMessages" v-slot="validationContext">
                <b-form-group>
                  <b-form-select :state="getValidationState(validationContext)"
                    id='installments' v-model="formStructure.installments.userInput" :options='$root.$data[formStructure.installments.optionRootSource] || formStructure.installments.options' >
                    <template v-slot:first>
                      <b-form-select-option value="undefined" disabled> {{ formStructure.installments.placeholder }}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.payment_choice.rules" :customMessages="formStructure.payment_choice.customErrorMessages"
               v-slot="validationContext">
                <b-form-group :label="formStructure.payment_choice.placeholder">
                  <b-form-radio-group :value="formStructure.payment_choice.userInput" :disabled='formStructure.payment_choice.readonly'
                    id='payment_choice' v-model="formStructure.payment_choice.userInput" :options="formStructure.payment_choice.choices" :state="getValidationState(validationContext)" type="radio">
                 </b-form-radio-group>
                <span style="color: red;">{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>



            <div v-if="formStructure.nome_payment_subject.render">
              <validation-provider ref="validtest" :rules="formStructure.nome_payment_subject.rules" :customMessages="formStructure.nome_payment_subject.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='nome_payment_subject'
                  v-model="formStructure.nome_payment_subject.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  :placeholder="formStructure.nome_payment_subject.placeholder"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.cognome_payment_subject.rules" :customMessages="formStructure.cognome_payment_subject.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='cognome_payment_subject'
                  v-model="formStructure.cognome_payment_subject.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  :placeholder="formStructure.cognome_payment_subject.placeholder"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.cf_payment_subject.rules" :customMessages="formStructure.cf_payment_subject.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='cf_payment_subject'
                  v-model="formStructure.cf_payment_subject.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  :placeholder="formStructure.cf_payment_subject.placeholder"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.birth_date_payment_subject.rules" :customMessages="formStructure.birth_date_payment_subject.customErrorMessages"
               v-slot="validationContext">
                <label for="birth_date_payment_subject">{{ formStructure.birth_date_payment_subject.placeholder }}</label>
                <b-form-datepicker id="birth_date_payment_subject" size="sm" :dark='true' v-model="formStructure.birth_date_payment_subject.userInput" :state="getValidationState(validationContext)"
                :initial-date="formStructure.birth_date_payment_subject.initialBirthDate"
                :min="formStructure.birth_date_payment_subject.minBirthDate" :max="formStructure.birth_date_payment_subject.maxBirthDate" class="mb-2">
                </b-form-datepicker>
                <span>{{ validationContext.errors[0] }}</span>
              </validation-provider>

              <validation-provider :rules="formStructure.birth_city_payment_subject.rules" :customMessages="formStructure.birth_city_payment_subject.customErrorMessages"
               v-slot="validationContext">
                <b-form-group>
                <b-form-input
                  id='birth_city_payment_subject'
                  v-model="formStructure.birth_city_payment_subject.userInput"
                  type="text"
                  :state="getValidationState(validationContext)"
                  :placeholder="formStructure.birth_city_payment_subject.placeholder"
                ></b-form-input>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </div>




              <validation-provider :rules="formStructure.flag_privacy_required.rules" :customMessages="formStructure.flag_privacy_required.customErrorMessages"
               v-slot="validationContext">
                <b-form-group :label="formStructure.flag_privacy_required.placeholder">
                  <b-form-checkbox v-model="formStructure.flag_privacy_required.userInput" :value="formStructure.flag_privacy_required.checked" :unchecked-value="formStructure.flag_privacy_required.unchecked"
                    id='flag_privacy_required' :state="getValidationState(validationContext)">
                    Autorizzo al trattamento dei dati personali per finalità connesse allo svolgimento delle attività istituzionali (obbligatorio)
                 </b-form-checkbox>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <validation-provider :rules="formStructure.flag_privacy_optional.rules" :customMessages="formStructure.flag_privacy_optional.customErrorMessages"
               v-slot="validationContext">
                <b-form-group  :label="formStructure.flag_privacy_optional.placeholder">
                  <b-form-checkbox v-model="formStructure.flag_privacy_optional.userInput" :value="formStructure.flag_privacy_optional.checked" :unchecked-value="formStructure.flag_privacy_optional.unchecked"
                    id='flag_privacy_optional' :state="getValidationState(validationContext)">
                    Autorizzo al trattamento dei dati per invio di materiale informativo (facoltativo)
                  </b-form-checkbox>
                <span>{{ validationContext.errors[0] }}</span>
                </b-form-group>
              </validation-provider>

              <span v-if="price">
                <p>Pagamento annuale: {{ price }} € divisi in {{ prospectus }} rate</p>
              </span>
              <b-form-group>
                <b-button v-for="(item, key) in step3FormEnrollButtons" :key="key"
                 :size="item.size" :variant="item.styleVariant" :type="item.type" :disabled="disableActionButtons"
                 @click="callByName(item.onClick)"> {{ item.label }}
                </b-button>
              </b-form-group>
                <a href="mailto:assistenza@unimercatorum.id" v-if="failstep3">  Bisogno di aiuto? Clicca qui per inviarci una mail </a>
          </validation-observer>

          <div class="mx-auto" v-show="step4EnrollDoneVisible">
            <h6>
              Iscrizione inviata con successo
            </h6>
              <p v-if="emailError">
                Per motivi tecnici non siamo stati in grado di inviarti un E-mail di conferma, ma la tua richiesta è stata comunque presa in carico.
                Riceverai una email di conferma entro 48 ore.
                <a href="mailto:assistenza@unimercatorum.id">  Per assistenza clicca qui per inviarci una mail </a>
              </p>
              <p v-else>
                Un messaggio di conferma è stata inviata al tuo indirizzo E-mail.
                Se non trovi il messaggio, cerca anche nella cartella di spam.
              </p>
          </div>

        </b-form>


        <div class="mx-auto" v-show="step5ErrorVisible">
          <p>
            Richiesta fallita.<a href="mailto:assistenza@unimercatorum.id">  Bisogno di aiuto? Clicca qui per inviarci una mail </a>
          </p>
          <p>
            {{ step5ErrorMessage }}
            <ul>
              <li class="mx-auto" v-for="item in step5ErrorList" :key="item">
               {{ item }}
             </li>
           </ul>
         </p>
          <b-button v-for="(item, key) in step5FormEnrollButtons" :key="key"
           :size="item.size" :variant="item.styleVariant" :type="item.type"
           @click="callByName(item.onClick)"> {{ item.label }}
          </b-button>
        </div>
      </div>
        <div v-else>
          <h3>
            Attenzione, stiamo riscontrando momentanei disservizi ai nostri sistemi. L'iscrizione è temporaneamente non disponibile. Prova a ricaricare la pagina. <a href="mailto:assistenza@unimercatorum.id"> Per assistenza clicca qui per inviarci una mail</a>
          </h3>
        </div>
</template>


<script>
import { ValidationProvider,ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
extend('formprogrequired', {
  validate (value) {
    return {
      required: false,
      valid: ![undefined].includes(value)
    };
  },
  computesRequired: true,
  message: 'Campo obbligatorio'
});

const _ = require('lodash');
const util = require('util');
//const themes = require('../assets/themes.json');
  export default {
    name: 'public-form',
    components: {
      ValidationProvider,
      ValidationObserver
    },

    data() {
      return {
    //    themes: require('../assets/themes.json'),
        internalShow: true, // per mostrare eventuale dicitura manutenzione
        internalShowFormLead: false,
        internalShowFormEnroll: false,
        actionMetaDataEnroll: {
          action: 'POST',
          toastOptionsOk: { // non usato per ora
            toastTitle: `Iscrizione`,
            toastContentModel: `Iscrizione effettuata`,
            toastFormatParams: [],
            toastContent: '',
            autoHideDelay: 5000
          },
          toastOptionsError: { // non usato per ora
            toastTitle: 'Iscrizione',
            toastContentModel: `Iscrizione non andata a buon fine a causa di: %s`,
            toastContent: '',
            autoHideDelay: 10000
          },
          apiCall: process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat(`/public/1`),
          apiCallUrlParams: [],
          apiCallGetParams: [],
          apiCallPostParams: ['nome', 'cognome', 'cf', 'email', 'telephone', 'birth_date', 'sex', 'residence_address_name', 'residence_address_num', 'flag_privacy_required', 'flag_privacy_optional', 'other_city_birth', 'other_city_residence', 'residence_cap', 'birth_city_id', 'residence_city_id', 'birth_country_id', 'residence_country_id', 'enroll_type_id', 'pds_id', 'convention_id', 'installments', 'formativeprogram', 'program', 'payment_choice', 'doppia_laurea', 'nome_payment_subject', 'cognome_payment_subject', 'cf_payment_subject', 'birth_date_payment_subject', 'birth_city_payment_subject', 'external_universities'],
          timeout: 5000,
          response: '',
          error: ''
        },
        actionMetaDataLead: {
          action: 'POST',
          toastOptionsOk: { // non usato per ora
            toastTitle: `Richiesta informazioni`,
            toastContentModel: `Richiesta effettuata`,
            toastFormatParams: [],
            toastContent: '',
            autoHideDelay: 5000
          },
          toastOptionsError: { // non usato per ora
            toastTitle: 'Richiesta informazioni',
            toastContentModel: `Richiesta non andata a buon fine a causa di: %s`,
            toastContent: '',
            autoHideDelay: 10000
          },
          apiCall: process.env.VUE_APP_API_BACKEND_PREFIX.concat(this.$root.$data.domain).concat(`/public/13`),
          apiCallUrlParams: [],
          apiCallGetParams: [],
          apiCallPostParams: ['nome', 'cognome', 'email', 'telephone'],
          timeout: 5000,
          response: '',
          error: ''
        },
        formStructure: {
          nome: {
            userInput: '',
            type: 'text',
            rules: {required:true,  alpha_spaces:true},
            customErrorMessages: {
              alpha_spaces: 'Consentite solo lettere',
              required: 'Campo obbligatorio'
            }
          },

          cognome: {
            userInput: '',
            type: 'text',
            rules: {required:true,  alpha_spaces:true},
            customErrorMessages: {
              alpha_spaces: 'Consentite solo lettere',
              required: 'Campo obbligatorio'
            }
          },

          cf: {
            userInput: '',
            type: 'text',
            rules: {required:true, regex: /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/},
            customErrorMessages: {
              required: 'Campo obbligatorio',
              regex: 'Deve essere un codice fiscale valido'
            }
          },

          email: {
            userInput: '',
            type: 'text',
            rules: {required:true,email:true, max:60},
            customErrorMessages: {
              required: 'Campo obbligatorio',
              email: 'Dev\'essere un\'email valida',
              max: 'Email troppo lunga'
            }
          },

          telephone: {
            userInput: '',
            type: 'text',
            rules: {required:true, regex: /^[+]?[0-9]{6,30}$/},
            customErrorMessages: {
              required: 'Campo obbligatorio',
              regex: 'Deve essere un numero di telefono valido'
            }
          },

          birth_date: {
            userInput: '',
            type: 'datepicker',
            rules: {required:true},
            initialBirthDate: new Date( ( (new Date().getFullYear())-20 ), new Date().getMonth(), new Date().getDate()),
            minBirthDate: '1950-01-01',
            maxBirthDate: new Date( ( (new Date().getFullYear())-17 ), new Date().getMonth(), new Date().getDate()) ,
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            placeholder: 'Data di nascita'
          },

          sex: {
            userInput: '',
            type: 'radio',
            choices: [ 'M', 'F'],
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            placeholder: 'Sesso'
          },

          residence_address_name: {
            userInput: '',
            type: 'text',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            }
          },

          residence_address_num: {
            userInput: '',
            type: 'text',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio',
            }
          },

          residence_cap: {
            userInput: '',
            type: 'text',
            rules: {required:true,min:4,max:10},
            render: false,
            customErrorMessages: {
              required: 'Campo obbligatorio',
              min: 'Deve avere almeno 4 caratteri',
              max: 'Deve avere al massimo 10 caratteri'
            },
            placeholder: 'CAP città di residenza'
          },

          payment_choice: {
            userInput: '',
            type: 'radio',
            choices: [ { text: 'Dichiaro che il sottoscritto coincide con il soggetto titolare del pagamento delle rette accademiche, i presenti dati anagrafici saranno utilizzati al fine dell’assicurazione in oggetto.', value: 1 },
            { text: 'Dichiaro di non essere il titolare del pagamento delle rette accademiche, pertanto il soggetto assicurato è persona diversa dal sottoscritto (INDICARE I DATI DI SEGUITO RICHIESTI DEL SOGGETTO ASSICURATO, OVVEROSIA TITOLARE DEL PAGAMENTO DELLE RETTE ACCADEMICHE)', value: 2 }],
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            placeholder: 'Pagamento retta scolastica'
          },

          nome_payment_subject: {
            userInput: '',
            type: 'text',
            rules: {required:true,  alpha_spaces:true},
            customErrorMessages: {
              alpha_spaces: 'Consentite solo lettere',
              required: 'Campo obbligatorio'
            },
            render: false,
            placeholder: 'Nome titolare pagamento'
          },

          cognome_payment_subject: {
            userInput: '',
            type: 'text',
            rules: {required:true,  alpha_spaces:true},
            customErrorMessages: {
              alpha_spaces: 'Consentite solo lettere',
              required: 'Campo obbligatorio'
            },
            placeholder: 'Cognome titolare pagamento'
          },

          cf_payment_subject: {
            userInput: '',
            type: 'text',
            rules: {required:true, regex: /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/},
            customErrorMessages: {
              required: 'Campo obbligatorio',
              regex: 'Deve essere un codice fiscale valido'
            },
            placeholder: 'Codice fiscale titolare pagamento'
          },

          birth_date_payment_subject: {
            userInput: '',
            type: 'datepicker',
            rules: {required:true},
            initialBirthDate: new Date( ( (new Date().getFullYear())-30 ), new Date().getMonth(), new Date().getDate()),
            minBirthDate: '1935-01-01',
            maxBirthDate: new Date( ( (new Date().getFullYear())-17 ), new Date().getMonth(), new Date().getDate()) ,
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            placeholder: 'Data di nascita titolare pagamento'
          },

          birth_city_payment_subject: {
            userInput: '',
            type: 'text',
            render: false,
            rules: {required:true}, //{required_if:'birth_city_id,""'},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            placeholder: 'Città di nascita titolare pagamento'
          },

          flag_privacy_required: {
            userInput: '',
            type: 'checkbox',
            checked: true,
            unchecked: '',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            placeholder: 'Accettazione privacy'
          },

          flag_privacy_optional: {
            userInput: false,
            type: 'checkbox',
            checked: true,
            unchecked: false,
            rules: {},
            customErrorMessages: {
            },
            placeholder: 'Accettazione privacy'
          },

          other_city_birth: {
            userInput: '',
            type: 'text',
            render: false,
            rules: {required:true}, //{required_if:'birth_city_id,""'},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            }
          },

          other_city_residence: {
            userInput: '',
            type: 'text',
            render: false,
            rules: {required:true}, //{required_if:'birth_city_id,""'},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            }
          },

          cdl_id: {
            userInput: undefined,
            type: 'select',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicCdlOptionsList',
            placeholder: 'Corso di laurea'
          },

          pds_id: {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicPdsOptionsList',
            placeholder: 'Piano di studi'
          },

          enroll_type_id: {
            userInput: undefined,
            type: 'select',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicEnrollsOptionsList',
            placeholder: 'Tipo iscrizione'
          },

          doppia_laurea: {
            userInput: undefined,
            type: 'select',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicDoppiaLaureaOptionsList',
            placeholder: 'Iscrizione ad altri atenei'
          },

          external_universities : {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicExternalUniversities',
            placeholder: 'Seleziona altro ateneo in cui sei iscritto'
          },

          academic_year: {
            userInput: undefined,
            type: 'select',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicAcademicYearOptionsList',
            placeholder: 'Anno accademico'
          },

          birth_country_id: {
            userInput: undefined,
            type: 'select',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicBirthCountriesList',
            placeholder: 'Nazione di nascita'
          },

          birth_province_id: {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicBirthProvincesList',
            placeholder: 'Provincia di nascita'
          },

          birth_city_id: {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicBirthCitiesList',
            placeholder: 'Città di nascita'
          },

          residence_country_id: {
            userInput: undefined,
            type: 'select',
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicResidenceCountriesList',
            placeholder: 'Nazione di residenza'
          },

          residence_province_id: {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicResidenceProvincesList',
            placeholder: 'Provincia di residenza'
          },

          residence_city_id: {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicResidenceCitiesList',
            placeholder: 'Città di residenza'
          },
          convention_id : {
            userInput: '',
            type: 'select',
            render: false,
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicConventionsList',
          },
          installments : {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicInstallmentsList',
            placeholder: 'Numero rate del pagamento'
          },
          formativeprogram : {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {formprogrequired:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicFormativeProgramsList',
            placeholder: 'Programma formativo'
          },
          program : {
            userInput: undefined,
            type: 'select',
            render: false,
            rules: {required:true},
            customErrorMessages: {
              required: 'Campo obbligatorio'
            },
            options : [],
            optionRootSource: 'publicProgramsList',
            placeholder: 'Programma'
          }
        },

        formTitle: 'Iscrizione',
        step1FormEnrollVisible: true,
        failstep1: false,
        step2FormEnrollVisible: false,
        failstep2: false,
        step3FormEnrollVisible: false,
        failstep3: false,
        step1FormLeadVisible: true,
        failStep1Lead: false,
        step4EnrollDoneVisible: false,
        step2LeadDoneVisible: false,
        emailError: false,
        step5ErrorVisible: false,
        step5ErrorMessage: '',
        step5ErrorList: [],
        step1FormEnrollButtons: [
          {
            label: 'Continua',
            type: 'button',
            onClick: 'validateStep1',
            styleVariant: 'outline-success',
            size: 'sm'
          }
        ],
        step2FormEnrollButtons: [
          {
            label: 'Indietro',
            type: 'button',
            onClick: 'getStep1',
            styleVariant: 'outline-danger',
            size: 'sm'
          },
          {
            label: 'Continua',
            type: 'button',
            onClick: 'validateStep2',
            styleVariant: 'outline-success',
            size: 'sm'
          }
        ],
        step3FormEnrollButtons: [
          {
            label: 'Indietro',
            type: 'button',
            onClick: 'getStep2',
            styleVariant: 'outline-danger',
            size: 'sm'
          },
          {
            label: 'Iscriviti!',
            type: 'button',
            onClick: 'onSubmitEnroll',
            styleVariant: 'outline-success',
            size: 'sm'
          }
        ],
          step5FormEnrollButtons: [
            {
              label: 'Clicca per modificare i dati e riprovare',
              type: 'button',
              onClick: 'getStep1',
              styleVariant: 'outline-primary',
              size: 'sm'
            },
        ],
        step1FormLeadButtons: [
          {
            label: 'Invia richiesta',
            type: 'button',
            onClick: 'onSubmitLead',
            styleVariant: 'outline-success',
            size: 'sm'
          }
        ],
        disableActionButtons: false,

        correlatedFormData: this.correlatedObjects,

        price: undefined,
        prospectus: undefined,
        clientId: undefined,
        uniId: process.env.VUE_APP_UNIID
      }
    },

    computed: {

      step3MultipleWatcherForQuotas() {
          return `${this.formStructure.cdl_id.userInput}|${this.formStructure.program.userInput}|${this.formStructure.formativeprogram.userInput}|${this.formStructure.installments.userInput}|${this.formStructure.convention_id.userInput}`;
        }

  /*    cssProps() {
        return {
          'background-color': this.themes[this.$route.params.theme].backgroundcolor,
          'color': this.themes[this.$route.params.theme].color
        }
      },
      colors() {
        return '.form-control::placeholder {color:red !important}'//this.themes[this.$route.params.theme].color
      }
        /*::placeholder {
          color: red !important;
        }*/

    //    colors() {
    //      return{ color: 'red'}//this.themes[this.$route.params.theme].color
    //    }
//      birth_country_id: function() {
  //      return this.formStructure.birth_country_id.userInput;
//      },

    },

    watch: {
        '$root.$data.publicUniDefaultConvention.defaultConventionId': function(selected_default_convention) {
          this.formStructure.convention_id.userInput = selected_default_convention;
        },

        'formStructure.cdl_id.userInput': async function(selected_cdl_id) {
          this.formStructure.pds_id.userInput = undefined;
          this.formStructure.formativeprogram.userInput = undefined;
          await this.$refs.validProvFormProgram.validate();
          if ( selected_cdl_id !== undefined ) {
            this.$root.$emit('update-publicformativeprograms-list', selected_cdl_id);
            this.formStructure.pds_id.render = true;
            this.formStructure.pds_id.options = this.$root.$data.publicPdsOptionsList.filter( function (object) {
              return object.cdl_id === selected_cdl_id;
            } );
          } else {
            this.formStructure.pds_id.render = false;
            this.formStructure.pds_id.options = undefined;
          }
        },

        'formStructure.birth_country_id.userInput': function(selected_country_id) {
          if ( selected_country_id == this.$root.$data.publicBirthProvincesList[0].country_id) {
            this.formStructure.birth_province_id.render = true;
            this.formStructure.birth_city_id.render = true;
            this.formStructure.other_city_birth.render = false;
            this.formStructure.other_city_birth.userInput = null;
          }
          else {
            this.formStructure.birth_province_id.render = false;
            this.formStructure.birth_city_id.render = false;
            this.formStructure.other_city_birth.render = true;
            this.formStructure.birth_city_id.userInput = null;
          }
        },

        'formStructure.birth_province_id.userInput': function(selected_province_id) {
          this.formStructure.birth_city_id.userInput = undefined;
          this.formStructure.birth_city_id.options = this.$root.$data.publicBirthCitiesList.filter( function (object) {
            return object.province_id === selected_province_id;
          } );
        },

        'formStructure.residence_country_id.userInput': function(selected_country_id) {
          if ( selected_country_id == this.$root.$data.publicResidenceProvincesList[0].country_id) {
            this.formStructure.residence_province_id.render = true;
            this.formStructure.residence_city_id.render = true;
            this.formStructure.other_city_residence.render = false;
            this.formStructure.other_city_residence.userInput = null;
            this.formStructure.residence_cap.render = true;
            this.formStructure.residence_cap.userInput = '';

          }
          else {
            this.formStructure.residence_province_id.render = false;
            this.formStructure.residence_city_id.render = false;
            this.formStructure.other_city_residence.render = true;
            this.formStructure.residence_city_id.userInput = null;
            this.formStructure.residence_cap.render = true;
            this.formStructure.residence_cap.userInput = '';

          }
        },


        'formStructure.residence_province_id.userInput': function(selected_province_id) {
          this.formStructure.residence_city_id.userInput = undefined;
          this.formStructure.residence_city_id.options = this.$root.$data.publicResidenceCitiesList.filter( function (object) {
            return object.province_id === selected_province_id;
          } );
        },

        'formStructure.formativeprogram.userInput': async function(selected_formative_program) {
          if ( selected_formative_program !== undefined ) {
            let selectedItem = {};
            for ( const element of this.$root.$data.publicFormativeProgramsList ) {
              if (element.value === selected_formative_program) {
                selectedItem = element;
              }
            }
            this.formStructure.program.userInput = undefined;
            this.$root.$emit('update-publicprograms-list', selectedItem.formProgId);
          }
        },

        'formStructure.payment_choice.userInput': function(selected_payment_choice) {
          if ( selected_payment_choice == 1) {
            this.formStructure.nome_payment_subject.render = false;
          }
          else {
            this.formStructure.nome_payment_subject.render = true;
          }
        },

        'formStructure.doppia_laurea.userInput': function(selected_doppia_laurea) {
          if ( selected_doppia_laurea === 'A') {
            this.formStructure.external_universities.render = true;
            if ( this.$root.$data.publicExternalUniversities.length === 0 ) {
              this.$root.$emit('update-externalUniversities-list');
            }
          } else {
            this.formStructure.external_universities.render = false;
          }
        },

        step3MultipleWatcherForQuotas : async function(aggregateValue) {
          if ( this.internalShowFormEnroll === true ) { // workaround for leads form
            const validProvInstallments = await this.$refs.validProvInstallments.validateSilent();
            const validProvProgram = await this.$refs.validProvProgram.validateSilent();
            const validProvConvention = await this.$refs.validProvConvention.validateSilent();
            const validProvCdl = await this.$refs.validProvCdl.validateSilent();
            const validProvFormProgram = await this.$refs.validProvFormProgram.validateSilent();

            if (validProvInstallments.valid && validProvProgram.valid && validProvConvention.valid && validProvCdl.valid && validProvFormProgram.valid ) {

              const [ selected_cdl_id, selectedProgram, selectedFormativeProgram, selectedInstallment, selectedConvention_id ] = aggregateValue.split('|');
              let [ cdl_code, program_id_original, label, installments_id_original, convention_id_original ] = [undefined, undefined, undefined, undefined, undefined];

              for ( const cdlElement of this.$root.$data.publicCdlOptionsList ) {
                if (cdlElement.value == selected_cdl_id) {
                  cdl_code = cdlElement.code;
                  break;
                }
              }
              for ( const programElement of this.$root.$data.publicProgramsList ) {
                if (programElement.value == selectedProgram) {
                  program_id_original = programElement.id_original;
                  break;
                }
              }

              label = selectedFormativeProgram;

              for ( const installmentElement of this.$root.$data.publicInstallmentsList ) {
                if (installmentElement.value == selectedInstallment) {
                  this.prospectus = installmentElement.text;
                  installments_id_original = installmentElement.id_original;
                  break;
                }
              }

              for ( const conventionElement of this.$root.$data.publicConventionsList ) {
                if (conventionElement.value == selectedConvention_id) {
                  convention_id_original = conventionElement.id_original;
                  break;
                }
              }

              let postParamsValues =
                {"academicYear": this.$root.$data.publicCurrentYear,
                "label":label,
                "program": program_id_original,
                "installments":installments_id_original,
                "course_code": cdl_code,
                "convention_id":convention_id_original,
                "promo_code":null,
                "fiscal_code":null};

              this.$axios({
                url: this.$root.$data.publicUniApiCallProspectus, //'https://ecp-mercatorum.multiversity.click/api/get/graduate/prospectus',
                method: 'POST',
                data: postParamsValues,
                timeout: 2000
              })
              .then( (response) => {
                this.price = response.data.program.import;
              })
              .catch( () => {
                this.price = undefined;
              })

            } else {
              this.price = undefined;
            }
          }
        }

    },

    methods: {

    showLeadForm() {
      this.internalShowFormEnroll = false;
      this.internalShowFormLead = true;
    },

    showEnrollForm() {
      this.internalShowFormLead = false;
      this.internalShowFormEnroll = true;
    },

    async validateStep1() {
        let success = await this.$refs.observer1.validate();
          if (!success) {
            this.failstep1 = true;
            return;
          }
          this.failstep1 = false;
          this.step1FormEnrollVisible = false;
          this.step2FormEnrollVisible = true;
          /* default country set */
          this.formStructure.birth_country_id.userInput = process.env.VUE_APP_DEFAULT_COUNTRY_ID;
          this.formStructure.residence_country_id.userInput = process.env.VUE_APP_DEFAULT_COUNTRY_ID;
          /* default country set */
          document.getElementById('iscriviti').scrollIntoView();
      },

      getStep1() {
            this.step1FormEnrollVisible = true;
            this.failstep1 = false;
            this.step2FormEnrollVisible = false;
            this.step5ErrorVisible = false;
            this.step5ErrorList = [];
      },


      async validateStep2() {
          let success = await this.$refs.observer2.validate();
            if (!success) {
              this.failstep2 = true;

              return;
            }
            this.failstep2 = false;
            this.step2FormEnrollVisible = false;
            this.step3FormEnrollVisible = true;
            document.getElementById('iscriviti').scrollIntoView();
        },

        getStep2() {
              this.step2FormEnrollVisible = true;
              this.failstep2 = false;
              this.step3FormEnrollVisible = false;
        },

      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },

      onSubmitLead: async function() {
        let success = await this.$refs.observerLead.validate();
        if (!success) {
          this.failStep1Lead = true;
          return;
        }
        this.$root.$emit('loading', true);
        let method = this.actionMetaDataLead.action;
        let timeout = this.actionMetaDataLead.timeout;

        let urlParamskeys = this.actionMetaDataLead.apiCallUrlParams;
        let getParamskeys = this.actionMetaDataLead.apiCallGetParams;
        let postParamskeys = this.actionMetaDataLead.apiCallPostParams;
        let urlParamsValues = [];
        let getParamsValues = {};
        let postParamsValues = {};

        postParamsValues.client_id = this.clientId;

        let params = _.mapValues(this.formStructure,
          function iteratee(value) {
            if ( value.onSubmitTransmuter != undefined) {
              value.userInput = value.onSubmitTransmuter(value.userInput);
            }
            return value.userInput;
          }
        );
        let paramsKeys = Object.keys(params);
        for ( let index = 0 ; index < paramsKeys.length ; index++ ) {
          if ( urlParamskeys.includes(paramsKeys[index]) ) {
            urlParamsValues.push( this.formStructure[paramsKeys[index]].userInput );
          }
          if ( getParamskeys.includes(paramsKeys[index]) ) {
            getParamsValues[paramsKeys[index]] = this.formStructure[paramsKeys[index]].userInput;
          }
          if ( postParamskeys.includes(paramsKeys[index]) ) {
            postParamsValues[paramsKeys[index]] = this.formStructure[paramsKeys[index]].userInput;
          }
        }

        let url = util.format(this.actionMetaDataLead.apiCall, ...urlParamsValues);
      //  this.$axios[method](url, params, { timeout: timeout })
        this.$axios({
          url: url,
          method: method,
          params: getParamsValues,
          data: postParamsValues,
          timeout: timeout
        })
        .then( (response) => {
          this.$root.$emit('submit');// submitted correctly event to handle
          this.step1FormLeadVisible = false;
          this.step2LeadDoneVisible = true;
          if (response.data.confirmEmail === 'ko') {
            this.emailError = true;
          }
          document.getElementById('iscriviti').scrollIntoView();
          this.$root.$emit('loading', false);
        })
        .catch( (error) => {
          this.step5ErrorList = [];
          this.step5ErrorMessage = '';
          if (error.code === 'ECONNABORTED' || error.response == undefined ) {
            this.step5ErrorMessage = ' i nostri sistemi stanno riscontrando delle difficoltà, riprova ad effettuare la richiesta';
          }
          else if ( error.response != undefined ) {
            if ( error.response.status === 400 && error.response.data.status === 'ko' && error.response.data.msg.trim() !== ''  ) {
              this.step5ErrorMessage = error.response.data.msg;
              if ( error.response.data.errorsList != undefined ) {
                for (let index = 0; index < error.response.data.errorsList.length; index++) {
                  this.$set(this.step5ErrorList, index, error.response.data.errorsList[index] );
                }
              }
            }
          }
          this.failStep1Lead = true;
          document.getElementById('iscriviti').scrollIntoView();


          this.$root.$emit('loading', false);
        })
        .then( () => {

        })
      },

      onSubmitEnroll: async function() {
        let success = await this.$refs.observer3.validate();
        if (!success) {
          this.failstep3 = true;
          return;
        }
        this.$root.$emit('loading', true);
        let method = this.actionMetaDataEnroll.action;
        let timeout = this.actionMetaDataEnroll.timeout;

        let urlParamskeys = this.actionMetaDataEnroll.apiCallUrlParams;
        let getParamskeys = this.actionMetaDataEnroll.apiCallGetParams;
        let postParamskeys = this.actionMetaDataEnroll.apiCallPostParams;
        let urlParamsValues = [];
        let getParamsValues = {};
        let postParamsValues = {};

        postParamsValues.client_id = this.clientId;

        let params = _.mapValues(this.formStructure,
          function iteratee(value) {
            if ( value.onSubmitTransmuter != undefined) {
              value.userInput = value.onSubmitTransmuter(value.userInput);
            }
            return value.userInput;
          }
        );
        let paramsKeys = Object.keys(params);
        for ( let index = 0 ; index < paramsKeys.length ; index++ ) {
          if ( urlParamskeys.includes(paramsKeys[index]) ) {
            urlParamsValues.push( this.formStructure[paramsKeys[index]].userInput );
          }
          if ( getParamskeys.includes(paramsKeys[index]) ) {
            getParamsValues[paramsKeys[index]] = this.formStructure[paramsKeys[index]].userInput;
          }
          if ( postParamskeys.includes(paramsKeys[index]) ) {
            postParamsValues[paramsKeys[index]] = this.formStructure[paramsKeys[index]].userInput;
          }
        }

        let url = util.format(this.actionMetaDataEnroll.apiCall, ...urlParamsValues);
      //  this.$axios[method](url, params, { timeout: timeout })
        this.$axios({
          url: url,
          method: method,
          params: getParamsValues,
          data: postParamsValues,
          timeout: timeout
        })
        .then( (response) => {
          this.$root.$emit('submit');// submitted correctly event to handle
          this.step3FormEnrollVisible = false;
          this.failstep3 = false;
          this.step4DoneVisible = true;
          if (response.data.confirmEmail === 'ko') {
            this.emailError = true;
          }
          document.getElementById('iscriviti').scrollIntoView();
          this.$root.$emit('loading', false);
        })
          .catch( (error) => {
            this.step5ErrorList = [];
            this.step5ErrorMessage = '';
            if (error.code === 'ECONNABORTED' || error.response == undefined ) {
              this.step5ErrorMessage = ' i nostri sistemi stanno riscontrando delle difficoltà, riprova ad effettuare l\'iscrizione';
            }
            else if ( error.response != undefined ) {
              if ( error.response.status === 400 && error.response.data.status === 'ko' && error.response.data.msg.trim() !== ''  ) {
                this.step5ErrorMessage = error.response.data.msg;
                if ( error.response.data.errorsList != undefined ) {
                  for (let index = 0; index < error.response.data.errorsList.length; index++) {
                    this.$set(this.step5ErrorList, index, error.response.data.errorsList[index] );
                  }
                }
              }
            }
            this.step3FormEnrollVisible = false;
            this.step5ErrorVisible = true;
            this.failstep3 = false;
            document.getElementById('iscriviti').scrollIntoView();


            this.$root.$emit('loading', false);
          })
          .then( () => {

          })
      },

      callByName: function(aFuncName){
        if(aFuncName) {
          this[aFuncName]();
        }
      },
/*
      setUserDataPayload: function() {
        let dataPayload = this.dataInjectionPayload;
        if (dataPayload != undefined) {
          let payloadDataEntriesArray = Object.keys(dataPayload);
          let formDataEntriesArray = Object.keys(this.formStructure);
          for ( let index = 0 ; index < Object.keys(formDataEntriesArray).length ; index++ ) {
            if ( payloadDataEntriesArray.includes(formDataEntriesArray[index]) ) {
              this.formStructure[formDataEntriesArray[index]].userInput = dataPayload[formDataEntriesArray[index]];
            }
          }
        }
      },
*/
      makeToast: function ( options = { toastTitle: 'Title', toastContent: 'Info', autoHideDelay: 5000, appendToast: false } ) {
        this.$root.$bvToast.toast(options.toastContent, {
          title: options.toastTitle,
          autoHideDelay: options.autoHideDelay,
          appendToast: options.appendToast
        })
      }
    },
    mounted: function onMount() {
      this.$root.$on('loading', (state) => {
        this.disableActionButtons = state;
      })

      if ( this.$root.$data.publicBirthCitiesList.length == 0 || this.$root.$data.publicBirthProvincesList.length == 0 || this.$root.$data.publicBirthCountriesList.length == 0 ) {
        this.$root.$emit('update-birthlocations-list');
      }
      if ( this.$root.$data.publicResidenceCitiesList.length == 0 || this.$root.$data.publicResidenceProvincesList.length == 0 || this.$root.$data.publicResidenceCountriesList.length == 0  ) {
        this.$root.$emit('update-residencelocations-list');
      }
      if ( this.$root.$data.publicEnrollsOptionsList.length == 0 ) {
        this.$root.$emit('update-publicenrolls-list');
      }
      if ( this.$root.$data.publicPdsOptionsList.length == 0 || this.$root.$data.publicCdlOptionsList.length == 0 ) {
        this.$root.$emit('update-publicpds-list', this.uniId);
      }
      if ( this.$root.$data.publicConventionsList.length == 0 ) {
        this.$root.$emit('update-publicconventions-list', this.uniId);
      }
      if ( this.$root.$data.publicInstallmentsList.length == 0 ) {
        this.$root.$emit('update-publicinstallments-list');
      }
      if ( this.$root.$data.publicUniDefaultConvention.defaultConventionId == '') {
        this.$root.$emit('update-uniDefaultConvention', this.uniId);
      }
  //    this.$root.$on('default_convention', (value) => {
//        this.formStructure.convention_id.userInput = value;
  //    })
      this.$root.$on('connection-aborted', () => {
        this.showForm = false;
      })
      this.$gtag.query('get', process.env.VUE_APP_GTAG, 'client_id', (clientId) => {
        this.clientId = clientId;
      })
    }
  }
</script>
<style scoped>
  .buttonblock:hover{
    background-color:#802144;
  }
</style>

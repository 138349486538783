<template>
  <div id="app">
    <graphicalcontainer/>
  </div>
</template>

<script>
import graphicalcontainer from './components/Graphicalcontainer.vue';

export default {
  name: 'frontend-unimercatorum',
  components: {
    graphicalcontainer
  },
  data: () => {
    return {
    }
  }
}

</script>

<style>
.highlight {
  background-color: #FF0 !important;
  transition: background-color 3s linear;
}

.highlightoff {
  background-color: trasparent;
  transition: background-color 3s linear;
}
/*.bv-row {background-color: var(--place); }  themes not working*/
/*::placeholder {
  color: red !important;
}
.form-control::placeholder {color:var(--place) !important}

.form-control::placeholder {color:red !important}*/
</style>
